import { render, staticRenderFns } from "./Signin.vue?vue&type=template&id=05db1d54&scoped=true"
import script from "./Signin.vue?vue&type=script&lang=js"
export * from "./Signin.vue?vue&type=script&lang=js"
import style0 from "./Signin.vue?vue&type=style&index=0&id=05db1d54&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05db1d54",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VForm } from 'vuetify/lib/components/VForm';
installComponents(component, {VBtn,VForm})
