import { render, staticRenderFns } from "./LoginBase.vue?vue&type=template&id=cdcbd736&scoped=true"
import script from "./LoginBase.vue?vue&type=script&lang=js"
export * from "./LoginBase.vue?vue&type=script&lang=js"
import style0 from "./LoginBase.vue?vue&type=style&index=0&id=cdcbd736&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cdcbd736",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VRow})
